import includes from "core-js/library/fn/string/includes";
import arrayIncludes from "core-js/fn/array/includes";
import entries from "core-js/fn/object/entries";
import values from "core-js/fn/object/values";
import find from "core-js/fn/array/find";
import "core-js/fn/array/from";
import "core-js/fn/map";
import "babel-polyfill";

if (!Array.find) Array.find = find;
if (!Object.values) Object.values = values;
if (!Object.entries) Object.entries = entries;
if (!String.includes) String.includes = includes;
if (!Array.includes) Array.includes = arrayIncludes;
